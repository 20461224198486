'use client'

import Link from 'next/link'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styles from './CommonHeader.module.scss'
import iconEvent from '@/assets/images/icons/icon-event.png'
import iconHome from '@/assets/images/icons/icon-home.png'
import iconMyPage from '@/assets/images/icons/icon-mypage.png'
import FaIcon, {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from '@/components/icon/FaIcon/FaIcon'
import IconLinkUnit from '@/components/ui/IconTextUnit/IconLinkUnit'
import { PRODUCT_NAME } from '@/constants'
import { useIsMenuOpenedAtom } from '@/state/global'
import utilsStyles from '@/styles/modules/utils.module.scss'
import { getCookieValue } from '@/utils/cookie'

function CommonHeader({ isLoggedIn = false }: { isLoggedIn?: boolean }) {
  const [_, setIsMenuOpened] = useIsMenuOpenedAtom()
  const [isLoading, setIsLoading] = useState(false)
  const openMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpened(true)
  }

  const [isBannerVisible, setIsBannerVisible] = useState(true)

  const visibleHeight = '3.125rem' //size(50) // スマートバナーの高さ

  useEffect(() => {
    if (!isMobile) return
    const storedVisibility = getCookieValue('smartBannerDismissed')
    if (storedVisibility === 'true') {
      setIsBannerVisible(false)
    }
    document.documentElement.style.setProperty(
      '--smart-banner-height',
      isBannerVisible ? visibleHeight : '0rem',
    )
  }, [isBannerVisible])

  const logout = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (isLoading) return
    setIsLoading(true)
    location.href = `${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/v1/web/logout`
  }
  return (
    <header className={styles.componentStyle}>
      <div className={styles.container}>
        <Link href='/' className={styles.logo}>
          <img src='/cz-logo.png' alt={PRODUCT_NAME} />
        </Link>
        {isLoggedIn ? (
          <div>
            {/* PCのみ */}
            <div className={`${styles.auth} ${utilsStyles.onlyPc}`}>
              {pcHeaderLinks()}
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a
                href='#'
                onClick={(e) => {
                  logout(e)
                }}
                className={`${utilsStyles.primaryColor} ${styles.authLink} ${utilsStyles.textBold}`}
              >
                <FaIcon icon={faArrowRightFromBracket} />
                ログアウト
              </a>
            </div>
            {/* SPのみ */}
            {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
            <a
              className={`${styles.openMenuIcon} ${utilsStyles.onlySp}`}
              href='#'
              onClick={(e) => openMenu(e)}
            >
              <span />
              <span />
              <span />
            </a>
          </div>
        ) : (
          <div className={styles.auth}>
            {/* PCのみ */}
            {pcHeaderLinks()}
            <Link
              href='/auth/signin'
              className={`${styles.authLink} ${utilsStyles.primaryColor} ${utilsStyles.textBold}`}
            >
              <FaIcon icon={faArrowRightToBracket} />
              ログイン
            </Link>
          </div>
        )}
      </div>
    </header>
  )
}

function pcHeaderLinks() {
  return (
    <div className={styles.pcLinks}>
      <IconLinkUnit iconSrc={iconHome.src} text='ホーム' href='/' />
      <IconLinkUnit iconSrc={iconEvent.src} text='イベント' href='/events' />
      <IconLinkUnit
        iconSrc={iconMyPage.src}
        text='マイページ'
        href='/purchase'
      />
    </div>
  )
}

export default CommonHeader
